import React, { ReactElement, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useContext } from '@/components/app/context'
import Template from '@/components/app/Template'
import { getPage } from '@/themes'
import { ArticlePageProps } from '@/types/pages/article'
import { Article as ArticleModel } from '@/types/models/article'

/**
 * Article page
 */
export default function Article(props: ArticlePageProps): ReactElement {
  const { pageContext } = props
  const [{ articles }] = useContext()
  const { article: defaultArticle } = pageContext
  const { id: articleId } = defaultArticle
  const [article, setArticle] = useState<ArticleModel>(defaultArticle)
  const ArticlePage = getPage<ArticlePageProps>(`Article`)
  const passedProps: ArticlePageProps = {
    ...props,
    pageContext: {
      ...pageContext,
      article,
    },
  }

  useEffect(() => {
    if (articles[articleId]) {
      setArticle({
        ...articles[articleId],
        is_live: true,
      })
    }
  }, [articleId, articles])

  return (
    <Template {...passedProps}>
      <Helmet>
        <meta name="revision" content={String(defaultArticle.revision)} />
      </Helmet>
      <ArticlePage {...passedProps} />
    </Template>
  )
}
